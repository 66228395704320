import { requestApi } from "./index";

const api = {
  list: async (params = "") => requestApi("/admin2021/logs" + params),
  getStorageLogs: async (params = "") => requestApi("/admin2021/logs/storage-logs" + params),
  instagram: {
    list: async (params = "") => requestApi("/admin2021/instagram/logs-work" + params),
  },
};

export default api;
