<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Logs Instagram</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Logs Instagram</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getLogsList" class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Username</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập user name"
                      v-model="username"
                    />
                  </div>
                </form>
                <form @submit.prevent="getLogsList" class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Object Id</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập object id"
                      v-model="object_id"
                    />
                  </div>
                </form>
                <form @submit.prevent="getLogsList" class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Job Id</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập job id"
                      v-model="job_id"
                    />
                  </div>
                </form>
                <form class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Type</label>
                    <select class="form-select" v-model="type" @change="getLogsList">
                      <option value="">Tất cả</option>
                      <option value="like">Like</option>
                      <option value="follow">Follow</option>
                    </select>
                  </div>
                </form>
                <form class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Log type</label>
                    <select class="form-select" v-model="log_type" @change="getLogsList">
                      <option value="">Tất cả</option>
                      <option value="success">Đã duyệt</option>
                      <option value="pending">Chờ duyệt</option>
                      <option value="error">Làm lỗi</option>
                      <option value="removed">Xóa</option>
                    </select>
                  </div>
                </form>
                <form class="col-md-3">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getLogsList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <datatable
            :stt="true"
            :title="'Danh sách Instagram'"
            :columns="columns"
            :rows="manipulatedLogs"
            :defaultPerPage="10"
          >
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center">
              <button class="btn btn-success font-10 py-1 btn-xs">Action</button>
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import logsApi from "@/api/logs";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery } from "@/helpers/index";

export default {
  name: "LogsInstagram",
  components: {
    Datatable,
    LoadingVue,
  },
  data() {
    return {
      username: null,
      object_id: null,
      job_id: null,
      log_type: "",
      type: "",
      limit: 50,
      logs: [],
      loading: false,
      columns: [
        { label: "Id", field: "id" },
        { label: "Job Id", field: "job_id" },
        { label: "Object Id", field: "object_id" },
        { label: "Object Id Link", field: "object_id_link", ig_link_full: true },
        { label: "Type", field: "type" },
        { label: "Package name", field: "package_name" },
        { label: "Coin", field: "coin", numeric: true },
        { label: "Username", field: "username" },
        { label: "User Id", field: "user_id" },
        { label: "Ig Account Id", field: "ig_account_id" },
        { label: "Prices Config Id", field: "prices_config_id" },
        { label: "Status Message", field: "status_message" },
        { label: "Ngày tạo", field: "created_at", timeago: true },
      ],
    };
  },
  computed: {
    manipulatedLogs() {
      return this.logs.map((log) => {
        log.status_message = log.jobs.status_message;
        log.object_id_link = log.jobs.link;
        return log;
      });
    },
  },
  created() {
    this.getLogsList();
  },
  methods: {
    async getLogsList() {
      const params = {
        limit: this.limit,
        username: this.username,
        object_id: this.object_id,
        type: this.type,
        job_id: this.job_id,
        log_type: this.log_type,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await logsApi.instagram.list(queries);
      this.loading = false;
      if (response) {
        if (response?.success) {
          this.logs = response.data;
        } else {
          this.$toastr.error(response.message || "Tải logs Instagram không thành công !");
        }
      } else {
        this.$toastr.error("Tải logs Instagram không thành công !");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
